import './index.scss'

const emailInput = document.getElementById('email');
const fullName = document.getElementById('fullName');
const submitButton = document.getElementById('submit-button');
const emailError = document.getElementById('email-error');
const fullNameError = document.getElementById('full-name-error');
const subscriptionForm = document.getElementById('subscription-form');

const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const base_url = 'https://user-api-dev.nextgenbets.com/api/v1'
const record_user_api = `${ base_url }/early-access/create-user`

function isValidEmail(email) {
  return emailPattern.test(email);
}

function handleEmailInput() {
  const emailValue = emailInput.value.trim();

  emailError.textContent = '';

  if (!emailValue) {
    emailError.textContent = 'Email is required';
    return;
  }

  if (!isValidEmail(emailValue)) {
    emailError.textContent = 'Enter correct Email address.';
    return;
  }
}

emailInput.addEventListener('input',handleEmailInput);

function handleNameInput() {
    const  fullNameValue= fullName.value.trim();
  
    fullNameError.textContent = '';
  
    if (!fullNameValue) {
        fullNameError.textContent = 'Full name is required';
      return;
    }
  
    if (fullNameValue?.length<3) {
        fullNameError.textContent = 'Full name must have 3 characters.';
      return;
    }
}
  
fullName.addEventListener('input',handleNameInput);

subscriptionForm.addEventListener('submit',(event) => {
  event.preventDefault();

  handleEmailInput();
  handleNameInput();

  const emailValue = emailInput.value.trim();
  const fullNameValue = fullName.value.trim();

  submitForm(emailValue,fullNameValue);
});

function resetFormFields() {
  emailInput.value = '';
  fullName.value = '';
}

function showThankYouMessage() {
//   subscriptionForm.style.display = 'none';

  const container = document.getElementById('thank-you-container')
  const thankYouMessage = document.createElement('h4');
  thankYouMessage.textContent = 'Thank you for your submission!';
  thankYouMessage.classList.add('thank-you-message');
  thankYouMessage.classList.add('fade-in');

  container.appendChild(thankYouMessage)

  resetFormFields();

  setTimeout(() => {
    thankYouMessage.remove();
    subscriptionForm.style.display = 'block';
  },5000);
}

function showErrorMessage(message) {
    const container = document.getElementById('response-error-container')
    const responseError = document.createElement('h6');
    responseError.textContent = message;
    responseError.classList.add('thank-you-message');
    responseError.classList.add('fade-in');
  
    container.appendChild(responseError)
  
    resetFormFields();
  
    setTimeout(() => {
      responseError.remove();
      subscriptionForm.style.display = 'block';
    },2000);
  }
  
function submitForm(email,fullName) {
  const emailValue = emailInput.value.trim();
  if (!emailValue) {
    emailError.textContent = 'Email is required';
    return;
  }
  if (emailValue && !isValidEmail(emailValue)) {
    emailError.textContent = 'Enter correct Email address.';
    return;
  }
  submitButton.disabled = true;
  fetch(record_user_api,{
    method: 'POST',
    // mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json',
      'Cross-Origin-Resource-Policy': 'same-site'
    },
    body: JSON.stringify({
      email,
      fullName,
    }),
  })
    .then(async (response) => {
      if (response.ok) {
        resetFormFields();
        showThankYouMessage()
        submitButton.disabled = false;
        setTimeout(() => {
          window.open("https://beta.nextgenbets.com/","_self")
        },500);
      } else {
        const errorData =await response.json(); // Await the response data
        submitButton.disabled = false;
        showErrorMessage(errorData?.errors?.[0]?.description || 'Form submission failed');
        console.error('Form submission failed');
      }
    })
    .catch((error) => {
      showErrorMessage('Form submission failed')
      submitButton.disabled = false;
    });
}

